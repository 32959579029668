module.exports = [{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"monotype":{"projectId":"847ecb19-cb74-45f9-b379-92686dbe694b","loadAllFonts":true}},
    },{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://2020-presidential-candidates-tax-policy.urban.org"},
    },{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-392952-2","head":false,"anonymize":true},
    },{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-parsely-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apikey":"80cf182213","enableInDevelopment":false},
    },{
      plugin: require('/home/circleci/project/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
