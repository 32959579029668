// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/circleci/project/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/project/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issue-areas-js": () => import("/home/circleci/project/src/pages/issue-areas.js" /* webpackChunkName: "component---src-pages-issue-areas-js" */),
  "component---src-pages-print-js": () => import("/home/circleci/project/src/pages/print.js" /* webpackChunkName: "component---src-pages-print-js" */),
  "component---src-pages-tax-policies-js": () => import("/home/circleci/project/src/pages/tax-policies.js" /* webpackChunkName: "component---src-pages-tax-policies-js" */)
}

